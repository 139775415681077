[
    {
        "id": 1,
        "message": "",
        "subtitle": "Nuestras antenitas de vinil detectaron que quieres hacer un intercambio",
        "text": "¡No esperes más, crea tu cuenta y haz tratos increibles!"
    },
    {
        "id": 2,
        "message": "¿Nuevo en el vecindario?",
        "subtitle": "Créate una cuenta ya y sigue navegando por esta increíble plataforma",
        "text": ""
    },
    {
        "id": 3,
        "message": "¡Pst, Pst,! ¿Te digo un secreto?",
        "subtitle": "Me dijeron que por ahí que si creas una cuenta puedes navegar libremente.",
        "text": ""
    },
    {
        "id": 4,
        "message": "¡Alto ahí!",
        "subtitle": "Con que queriendo husmear en el vecindario, ¡eh!",
        "text": "¿Por qué no lo haces con todas las de la ley uniéndote?"
    }
]
