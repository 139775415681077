<div class="container-visor">
    <div
        #imageContainer
        class="container-img"
        (mousemove)="onDragging($event)"
        (wheel)="onMouseWheel($event)"
    >
        <img
            alt="close icon"
            src="assets/img/icons/cross.svg"
            class="img-close"
            (click)="dialogRef.close()"
        />

        <img
            [alt]="title"
            [src]="url"
            class="img-principal"
            [style.transform]="transformStyle"
            draggable="false"
            (mousedown)="startDragging($event)"
            (mouseup)="stopDragging()"
        />

        <div (click)="zoomIn()" class="btn_zoom_in"><span>+</span></div>
        <div (click)="zoomOut()" class="btn_zoom_out">-</div>
    </div>
</div>
