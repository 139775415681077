<!-- -->

<section>
    <div class="modal-question white-bg">
        <p class="text-center mt-0">
            <b class="color-black">{{ questionModal.message }}</b>
        </p>
        <div class="spacing"></div>
        <div class="d-flex m-auto justify-content-center">
            <app-button
                (notify)="closeModal()"
                [data]="{
                    title: btnCancel,
                    style: 'third',
                    type: 'emit',
                    uppercase: true,
                    disabled: false
                }">
            </app-button>
            <div class="small-spacing"></div>
            <app-button
                (notify)="closeModal(true)"
                [data]="{
                    title: btnSuccess,
                    style: 'primary',
                    type: 'emit',
                    uppercase: true,
                    disabled: false
                }">
            </app-button>
        </div>
    </div>
</section>
