import { Dialog } from '@angular/cdk/dialog';
import { EventEmitter, inject, Injectable, OnDestroy } from '@angular/core';

import { Platform } from '@angular/cdk/platform';
import { Account } from '@app/models/authentication/account.model';
import { LoginModalComponent } from '@app/modules/modals/login-modal/login-modal.component';
import { ModalQuestionComponent } from '@app/modules/modals/modal-question/modal-question.component';
import { NoticeComponent } from '@app/modules/modals/notice/notice.component';
import { VisorImgComponent } from '@app/modules/modals/visor-img/visor-img.component';
import { LoginObservable } from '@app/observables/login.observable';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OpenModalService implements OnDestroy {
    private readonly loginObservable = inject(LoginObservable);
    private readonly dialogModal = inject(Dialog);

    currentAccount: Account | null;
    loginSubscription = new Subscription();

    public noticeInfo$ = new EventEmitter<boolean | null>();
    public question$ = new EventEmitter<any>;
    private _platform = inject(Platform);

    constructor() {
        this.subscriptionLogin();
    }

    ngOnDestroy(): void {
        this.loginSubscription.unsubscribe();
    }

    private subscriptionLogin(): void {
        this.loginSubscription = this.loginObservable.data$.subscribe(
            (res: Account | null) => {
                this.currentAccount = res;
            }
        );
    }

    openModalLogin(): boolean {
        if (!this._platform.isBrowser) return true;
        if (!this.currentAccount) {
            const dialogRef = this.dialogModal.open(LoginModalComponent);
            dialogRef.closed.subscribe((response: any) => {
                if (response != undefined && response != null && response) {
                    this.dialogModal.closeAll();
                }
            });
            return false;
        }

        return true;
    }

    openNoticeInfo(option: number, message: string = ''): void {
        const dialogRef = this.dialogModal.open(NoticeComponent, {
            data: { option, message },
        });
        dialogRef.closed.subscribe((response: any) => {
            this.noticeInfo$.emit(response);
        });
    }

    question(message: string, option: string, extraData: any = {}): void {
        const dialogRef = this.dialogModal.open(ModalQuestionComponent, {
            data: { message, option, extraData },
        });
        dialogRef.closed.subscribe((response: any) => {
            if (response != undefined && response != null && response) {
                this.question$.emit({ option, ...extraData });
            }
        });
    }

    visorImg(url: string, title: string): void {
        this.dialogModal.open(VisorImgComponent, { data: { url, title } });
    }
}
