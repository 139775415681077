import { DialogRef } from '@angular/cdk/dialog';
import { Component, inject, OnDestroy } from '@angular/core';

import { ButtonComponent } from '@app/components/button/button.component';
import { Message } from '@app/models/modals/message.model';
import { NavigationService } from '@app/services/navigation/navigation.service';
import message from '../../../../assets/json/message.json';

@Component({
    selector: 'app-login-modal',
    standalone: true,
    imports: [ButtonComponent],
    templateUrl: './login-modal.component.html',
    styleUrl: './login-modal.component.sass',
})
export class LoginModalComponent implements OnDestroy {
    currentMessage: Message;
    public readonly dialogRef = inject(DialogRef<any>);
    private readonly navigationService = inject(NavigationService);

    constructor() {
        this.currentMessage =
            message[Math.floor(Math.random() * (3 - 0 + 1) + 0)];
    }

    ngOnDestroy(): void {
        this.dialogRef.close(true);
    }

    redirectPage(url: string): void {
        this.navigationService.navigatePage(url);
        this.dialogRef.close(true);
    }
}
