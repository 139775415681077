import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
    selector: 'app-visor-img',
    standalone: true,
    imports: [],
    templateUrl: './visor-img.component.html',
    styleUrl: './visor-img.component.sass',
})
export class VisorImgComponent {
    url: string = '';
    title: string = '';
    zoomLevel: number = 1;

    isDragging: boolean = false;
    startX: number = 0;
    startY: number = 0;
    offsetX: number = 0;
    offsetY: number = 0;
    maxZoom: number = 0;
    clickStartTime: number;
    clickEndTime: number;

    constructor(
        public dialogRef: DialogRef<void>,
        @Inject(DIALOG_DATA) public dataModal: { url: string; title: string }
    ) {
        this.url = dataModal.url;
        this.title = dataModal.title;
    }

    get transformStyle(): string {
        return `scale(${this.zoomLevel}) translate(${this.offsetX}px, ${this.offsetY}px)`;
    }

    zoomIn(): void {
        if (this.maxZoom <= 3) {
            this.zoomLevel *= 1.3;
            this.maxZoom += 1;
        }
    }

    zoomOut(): void {
        this.zoomLevel = Math.max(1, this.zoomLevel / 1.3);

        this.maxZoom = this.maxZoom <= 3 ? this.maxZoom-- : 0;
    }

    onClick(event: MouseEvent): void {
        this.startDragging(event);
        this.zoomIn();
    }

    startDragging(event: MouseEvent): void {
        this.clickStartTime = Date.now();
        this.isDragging = true;
        this.startX = event.clientX;
        this.startY = event.clientY;
    }

    onDragging(event: MouseEvent): void {
        if (this.isDragging) {
            const deltaX = event.clientX - this.startX;
            const deltaY = event.clientY - this.startY;
            this.offsetX += deltaX;
            this.offsetY += deltaY;
            this.startX = event.clientX;
            this.startY = event.clientY;
        }
    }

    stopDragging(): void {
        this.isDragging = false;
        this.clickEndTime = Date.now();

        const clickDuration = this.clickEndTime - this.clickStartTime;

        if (clickDuration < 200) {
            this.zoomIn();
        }
    }

    onMouseWheel(event: WheelEvent): void {
        if (event.deltaY > 0) this.zoomOut();
        else this.zoomIn();
    }
}
