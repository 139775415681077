<div class="container-modal" id="container">
    <div
        class="m-auto d-flex flex-direction-column align-items-center"
        id="modal">
        <img
            (click)="dialogRef.close()"
            class="imgCross"
            src="assets/img/icons/cross.svg" />

        <p class="text-center no-modifications">
            <b>{{ currentMessage.message }}</b>
        </p>
        <p class="text-center no-modifications">
            <b>{{ currentMessage.subtitle }}</b>
        </p>
        <p class="text-center no-modifications">
            <b>{{ currentMessage.text }}</b>
        </p>

        <div class="small-spacing"></div>
        <app-button
            (notify)="redirectPage('/autenticacion/registrarCuenta')"
            [data]="{
                title: 'Crear cuenta',
                style: 'primary',
                uppercase: true,
                type: 'emit'
            }">
        </app-button>
        <div class="small-spacing"></div>
        <app-button
            (notify)="redirectPage('/autenticacion/iniciarSesion')"
            [data]="{
                title: 'Iniciar Sesión',
                uppercase: true,
                type: 'emit'
            }">
        </app-button>
    </div>
</div>
